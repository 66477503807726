import {createEntityActions} from '@/songPage/actions/entities';

export const referentEntity = createEntityActions('referents');
export const annotationEntity = createEntityActions('annotations');
export const annotationEditEntity = createEntityActions('annotation_edits');

export const FRAGMENT_CLICKED = 'songPage/FRAGMENT_CLICKED';
export const fragmentClicked = ({fragment, baseAnnotation}) => ({
  type: FRAGMENT_CLICKED,
  fragment,
  baseAnnotation,
});

export const REFERENT_FETCHED = 'songPage/REFERENT_FETCHED';
export const referentFetched = ({result, entities}) => ({
  type: REFERENT_FETCHED,
  result,
  entities,
});

export const REFERENT_FETCH_FAILED = 'songPage/REFERENT_FETCH_FAILED';
export const referentFetchFailed = referent => ({
  type: REFERENT_FETCH_FAILED,
  referent,
});

export const ANNOTATION_CLOSED = 'songPage/ANNOTATION_CLOSED';
export const annotationClosed = ({id, closeMethod}) => ({
  type: ANNOTATION_CLOSED,
  id,
  closeMethod,
});

export const HOVERED_OVER_REFERENT = 'songPage/HOVERED_OVER_REFERENT';
export const hoveredOverReferent = baseAnnotationId => ({
  type: HOVERED_OVER_REFERENT,
  baseAnnotationId,
});

export const ANNOTATION_OPENED_FROM_PERMALINK = 'songPage/ANNOTATION_OPENED_FROM_PERMALINK';
export const annotationOpenedFromPermalink = ({fragment, baseAnnotation}) => ({
  type: ANNOTATION_OPENED_FROM_PERMALINK,
  fragment,
  baseAnnotation,
});

export const NAVIGATED_TO_FRAGMENT = 'songPage/NAVIGATED_TO_FRAGMENT';
export const navigatedToFragment = ({fragment, baseAnnotation}) => ({
  type: NAVIGATED_TO_FRAGMENT,
  fragment,
  baseAnnotation,
});

export const CREATE_ANNOTATION_CLICKED = 'songPage/CREATE_ANNOTATION_CLICKED';
export const createAnnotationClicked = (referent, callbacks) => ({
  type: CREATE_ANNOTATION_CLICKED,
  payload: referent,
  meta: {
    ...callbacks,
  },
});

export const ADD_ANNOTATION_TO_REFERENT = 'songPage/ADD_ANNOTATION_TO_REFERENT';
export const addAnnotationToReferent = (data, callbacks) => ({
  type: ADD_ANNOTATION_TO_REFERENT,
  payload: data,
  meta: {
    ...callbacks,
  },
});

export const CREATE_ANNOTATION_PLACEHOLDER = 'CREATE_ANNOTATION_PLACEHOLDER';
export const createAnnotationPlaceholder = (referentId, verified, callbacks) => ({
  type: CREATE_ANNOTATION_PLACEHOLDER,
  payload: {
    referentId,
    verified,
  },
  meta: {
    ...callbacks,
  },
});

export const ADD_ANNOTATION_PLACEHOLDER = 'ADD_ANNOTATION_PLACEHOLDER';
export const addAnnotationPlaceholder = payload => ({
  type: ADD_ANNOTATION_PLACEHOLDER,
  payload,
});

export const REMOVE_ANNOTATION_PLACEHOLDER = 'REMOVE_ANNOTATION_PLACEHOLDER';
export const removeAnnotationPlaceholder = ({referentId, annotationId}) => ({
  type: REMOVE_ANNOTATION_PLACEHOLDER,
  payload: {
    referentId,
    annotationId,
  },
});

export const ANNOTATION_CREATE_SUCCEEDED = 'songPage/ANNOTATION_CREATE_SUCCEEDED';
export const annotationCreateSucceeded = ({annotationId, referentId}) => ({
  type: ANNOTATION_CREATE_SUCCEEDED,
  payload: {
    annotationId,
    referentId,
  },
});

export const ADD_ANNOTATION_TO_REFERENT_SUCCEEDED = 'songPage/ADD_ANNOTATION_TO_REFERENT_SUCCEEDED';
export const addAnnotationToReferentSucceeded = ({annotationId, referentId}) => ({
  type: ADD_ANNOTATION_TO_REFERENT_SUCCEEDED,
  payload: {
    annotationId,
    referentId,
  },
});

export const ANNOTATION_CREATE_FAILED = 'songPage/ANNOTATION_CREATE_FAILED';
export const annotationCreateFailed = () => ({
  type: ANNOTATION_CREATE_FAILED,
});

export const ANNOTATION_EDIT_SUBMITTED = 'songPage/ANNOTATION_EDIT_SUBMITTED';
export const annotationEditSubmitted = (data, callbacks) => ({
  type: ANNOTATION_EDIT_SUBMITTED,
  payload: data,
  meta: {
    ...callbacks,
  },
});

export const ANNOTATION_EDIT_CANCELED = 'songPage/ANNOTATION_EDIT_CANCELED';
export const annotationEditCanceled = ({annotationId, beingCreated}) => ({
  type: ANNOTATION_EDIT_CANCELED,
  payload: {
    annotationId,
    beingCreated,
  },
});

export const ANNOTATION_EDIT_SUCCEEDED = 'songPage/ANNOTATION_EDIT_SUCCEEDED';
export const annotationEditSucceeded = payload => ({
  type: ANNOTATION_EDIT_SUCCEEDED,
  payload,
});

export const ANNOTATION_EDIT_CLICKED = 'songPage/ANNOTATION_EDIT_CLICKED';
export const annotationEditClicked = annotationId => ({
  type: ANNOTATION_EDIT_CLICKED,
  payload: annotationId,
});

export const ANNOTATION_COMMENT_INTEGRATED = 'songPage/ANNOTATION_COMMENT_INTEGRATED';
export const annotationCommentIntegrated = (annotationId, {integration}) => ({
  type: ANNOTATION_COMMENT_INTEGRATED,
  payload: {
    annotationId,
    integration,
  },
});

export const ANNOTATION_DELETE_CLICKED = 'songPage/ANNOTATION_DELETE_CLICKED';
export const annotationDeleteClicked = annotationId => ({
  type: ANNOTATION_DELETE_CLICKED,
  payload: {
    annotationId,
  },
});

export const ACCEPT_ANNOTATION = 'songPage/ACCEPT_ANNOTATION';
export const acceptAnnotation = (annotationId, {onSuccess, onError}) => ({
  type: ACCEPT_ANNOTATION,
  annotationId,
  onSuccess,
  onError,
});

export const REJECT_ANNOTATION = 'songPage/REJECT_ANNOTATION';
export const rejectAnnotation = (annotationId, {body, reason, onSuccess, onError}) => ({
  type: REJECT_ANNOTATION,
  annotationId,
  body,
  reason,
  onSuccess,
  onError,
});

export const ACCEPT_ANNOTATION_SUCCEEDED = 'songPage/ACCEPT_ANNOTATION_SUCCEEDED';
export const acceptAnnotationSucceeded = (annotationId, {result, entities}) => ({
  type: ACCEPT_ANNOTATION_SUCCEEDED,
  annotationId,
  result,
  entities,
});

export const REJECT_ANNOTATION_SUCCEEDED = 'songPage/REJECT_ANNOTATION_SUCCEEDED';
export const rejectAnnotationSucceeded = (annotationId, {result, entities}) => ({
  type: REJECT_ANNOTATION_SUCCEEDED,
  annotationId,
  result,
  entities,
});

export const ANNOTATION_INTEGRATION_CLEARED = 'songPage/ANNOTATION_INTEGRATION_CLEARED';
export const annotationIntegrationCleared = annotationId => ({
  type: ANNOTATION_INTEGRATION_CLEARED,
  payload: annotationId,
});

export const ANNOTATION_EDIT_PROPOPSAL_TOGGLED = 'songPage/ANNOTATION_EDIT_PROPOPSAL_TOGGLED';
export const annotationEditProposalToggled = (annotationId, {visible} = {}) => ({
  type: ANNOTATION_EDIT_PROPOPSAL_TOGGLED,
  payload: {
    annotationId,
    visible,
  },
});

export const ANNOTATION_EDIT_PROPOSALS_REQUESTED = 'songPage/ANNOTATION_EDIT_PROPOSALS_REQUESTED';
export const annotationEditProposalsRequested = annotationId => ({
  type: ANNOTATION_EDIT_PROPOSALS_REQUESTED,
  payload: annotationId,
});

export const ANNOTATION_EDIT_PROPOSALS_REQUESTED_FAILED = 'songPage/ANNOTATION_EDIT_PROPOSALS_REQUESTED_FAILED';
export const annotationEditProposalsFailed = (annotationId, {message}) => ({
  type: ANNOTATION_EDIT_PROPOSALS_REQUESTED_FAILED,
  payload: {
    annotationId,
    message,
  },
});

export const ANNOTATION_EDIT_PROPOSALS_REQUESTED_FETCHED = 'songPage/ANNOTATION_EDIT_PROPOSALS_REQUESTED_FETCHED';
export const annotationEditProposalsFetched = (annotationId, {result, entities}) => ({
  type: ANNOTATION_EDIT_PROPOSALS_REQUESTED_FETCHED,
  payload: {
    annotationId,
    result,
    entities,
  },
});

export const ACCEPT_ANNOTATION_EDIT_PROPOSAL = 'songPage/ACCEPT_ANNOTATION_EDIT_PROPOSAL';
export const acceptAnnotationEditProposal = (editId, {annotationId, onSuccess, onError}) => ({
  type: ACCEPT_ANNOTATION_EDIT_PROPOSAL,
  payload: {
    editId,
    onSuccess,
    onError,
    annotationId,
  },
});

export const ACCEPTED_ANNOTATION_EDIT_PROPOSAL = 'songPage/ACCEPTED_ANNOTATION_EDIT_PROPOSAL';
export const acceptedAnnotationEditProposal = (editId, {annotationId}) => ({
  type: ACCEPTED_ANNOTATION_EDIT_PROPOSAL,
  payload: {
    editId,
    annotationId,
  },
});

export const REJECT_ANNOTATION_EDIT_PROPOSAL = 'songPage/REJECT_ANNOTATION_EDIT_PROPOSAL';
export const rejectAnnotationEditProposal = (editId, {annotationId, onSuccess, onError}) => ({
  type: REJECT_ANNOTATION_EDIT_PROPOSAL,
  payload: {
    editId,
    onSuccess,
    onError,
    annotationId,
  },
});

export const ANNOTATION_EDIT_PROPOSAL_STATE_SET = 'songPage/ANNOTATION_EDIT_PROPOSAL_STATE_SET';
export const annotationEditProposalStateSet = (editId, {result, entities, annotationId}) => ({
  type: ANNOTATION_EDIT_PROPOSAL_STATE_SET,
  payload: {
    editId,
    result,
    entities,
    annotationId,
  },
});

export const SONG_DESCRIPTION_ANNOTATION_SUBMITTED = 'songPage/SONG_DESCRIPTION_ANNOTATION_SUBMITTED';
export const songDescriptionAnnotationSubmitted = (data, callbacks) => ({
  type: SONG_DESCRIPTION_ANNOTATION_SUBMITTED,
  payload: data,
  meta: callbacks,
});

export const ANNOTATION_REVERTED = 'songPage/ANNOTATION_REVERTED';
export const annotationReverted = (data, callbacks) => ({
  type: ANNOTATION_REVERTED,
  payload: data,
  meta: callbacks,
});

export const ANNOTATION_REVERT_SUCCEEDED = 'songPage/ANNOTATION_REVERT_SUCCEEDED';
export const annotationRevertSucceeded = () => ({
  type: ANNOTATION_REVERT_SUCCEEDED,
});

export const ANNOTATION_REVERT_FAILED = 'songPage/ANNOTATION_REVERT_FAILED';
export const annotationRevertFailed = () => ({
  type: ANNOTATION_REVERT_FAILED,
});

export const CLICK_AND_NAVIGATED_TO_FRAGMENT = 'songPage/CLICK_AND_NAVIGATED_TO_FRAGMENT';
export const clickAndNavigatedToFragment = ({fragment, baseAnnotation}) => ({
  type: CLICK_AND_NAVIGATED_TO_FRAGMENT,
  fragment,
  baseAnnotation,
});

export const ANNOTATION_EDIT_UPDATE_BODY = 'songPage/ANNOTATION_EDIT_UPDATE_BODY';
export const annotationEditUpdateBody = payload => ({
  type: ANNOTATION_EDIT_UPDATE_BODY,
  payload,
});

export const TOGGLE_ANNOTATION_COSIGN = 'songPage/TOGGLE_ANNOTATION_COSIGN';
export const annotationToogleCosign = ({annotationId, cosigned}, callbacks) => ({
  type: TOGGLE_ANNOTATION_COSIGN,
  payload: {annotationId, cosigned},
  meta: callbacks,
});

export const TOGGLE_ANNOTATION_PINNED_TO_PROFILE = 'songPage/TOGGLE_ANNOTATION_PINNED_TO_PROFILE';
export const annotationTogglePinnedToProfile = ({annotationId, currentlyPinned}, callbacks) => ({
  type: TOGGLE_ANNOTATION_PINNED_TO_PROFILE,
  payload: {annotationId, currentlyPinned},
  meta: callbacks,
});
