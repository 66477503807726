import {useCallback, useRef} from 'react';
import {useDispatch} from 'react-redux';
import {mixpanelEventTriggered} from '@/actions';
import isEqual from 'lodash/isEqual';

export default (eventName, eventProps) => {
  const dispatch = useDispatch();
  const eventPropsRef = useRef(eventProps);

  if (!isEqual(eventPropsRef.current, eventProps)) {
    eventPropsRef.current = eventProps;
  }

  return useCallback((_, additionalEventProps) => {
    dispatch(mixpanelEventTriggered(eventName, {...eventPropsRef.current, ...additionalEventProps}, {
      send_immediately: true,
      transport: 'sendBeacon',
    }));
  }, [dispatch, eventName]);
};
