import {createEntityActions} from '@/songPage/actions/entities';

export const songEntity = createEntityActions('songs');

export const SONG_LOADED = 'songPage/SONG_LOADED';
export const songLoaded = songId => ({
  type: SONG_LOADED,
  songId,
});

export const EXPANDABLE_CONTENT_EXPANDED = 'songPage/EXPANDABLE_CONTENT_EXPANDED';
export const expandableContentExpanded = section => ({
  type: EXPANDABLE_CONTENT_EXPANDED,
  section,
});

export const EXPANDABLE_CONTENT_COLLAPSED = 'songPage/EXPANDABLE_CONTENT_COLLAPSED';
export const expandableContentCollapsed = section => ({
  type: EXPANDABLE_CONTENT_COLLAPSED,
  section,
});

export const LYRICS_EDIT_TOGGLED = 'songPage/LYRICS_EDIT_TOGGLED';
export const lyricsEditToggled = ({context, revertContext} = {}) => ({
  type: LYRICS_EDIT_TOGGLED,
  context,
  revertContext,
});

export const LYRICS_EDIT_SET = 'songPage/LYRICS_EDIT_SET';
export const lyricsEditSet = lyrics => ({
  type: LYRICS_EDIT_SET,
  lyrics,
});

export const LYRICS_EDIT_SUBMITTED = 'songPage/LYRICS_EDIT_SUBMITTED';
export const lyricsEditSubmitted = (songId, {hideFormAfterSubmit = false}) => ({
  type: LYRICS_EDIT_SUBMITTED,
  songId,
  hideFormAfterSubmit,
});

export const LYRICS_EDIT_FAILED = 'songPage/LYRICS_EDIT_FAILED';
export const lyricsEditFailed = (songId, {message, errors = [], blockEdit = false}) => ({
  type: LYRICS_EDIT_FAILED,
  songId,
  message,
  errors,
  blockEdit,
});

export const LYRICS_EDIT_UPDATED = 'songPage/LYRICS_EDIT_UPDATED';
export const lyricsEditUpdated = (songId, lyricsData, currentUserMetadata) => ({
  type: LYRICS_EDIT_UPDATED,
  songId,
  lyricsData,
  currentUserMetadata,
});

export const LYRICS_FOR_EDIT_PROPOSAL_REQUESTED = 'songPage/LYRICS_FOR_EDIT_PROPOSAL_REQUESTED';
export const lyricsForEditProposalRequested = songId => ({
  type: LYRICS_FOR_EDIT_PROPOSAL_REQUESTED,
  songId,
});

export const LYRICS_FOR_EDIT_PROPOSAL_FAILED = 'songPage/LYRICS_FOR_EDIT_PROPOSAL_FAILED';
export const lyricsForEditProposalFailed = (songId, {message, errors = [], blockEdit = false}) => ({
  type: LYRICS_FOR_EDIT_PROPOSAL_FAILED,
  songId,
  message,
  errors,
  blockEdit,
});

export const LYRICS_FOR_EDIT_PROPOSAL_FETCHED = 'songPage/LYRICS_FOR_EDIT_PROPOSAL_FETCHED';
export const lyricsForEditProposalFetched = (songId, {lyrics, version}) => ({
  type: LYRICS_FOR_EDIT_PROPOSAL_FETCHED,
  songId,
  lyrics,
  version,
});

export const LYRICS_EDIT_PROPOSAL_SUBMITTED = 'songPage/LYRICS_EDIT_PROPOSAL_SUBMITTED';
export const lyricsEditProposalSubmitted = songId => ({
  type: LYRICS_EDIT_PROPOSAL_SUBMITTED,
  songId,
});

export const LYRICS_EDIT_PROPOSAL_FAILED = 'songPage/LYRICS_EDIT_PROPOSAL_FAILED';
export const lyricsEditProposalFailed = (songId, {message, errors = [], blockEdit = false}) => ({
  type: LYRICS_EDIT_PROPOSAL_FAILED,
  songId,
  message,
  errors,
  blockEdit,
});

export const LYRICS_EDIT_PROPOSED = 'songPage/LYRICS_EDIT_PROPOSED';
export const lyricsEditProposed = songId => ({
  type: LYRICS_EDIT_PROPOSED,
  songId,
});

export const LYRICS_EDIT_ERROR_CLEARED = 'songPage/LYRICS_EDIT_ERROR_CLEARED';
export const lyricsEditErrorsCleared = index => ({
  type: LYRICS_EDIT_ERROR_CLEARED,
  index,
});

export const SONG_REFRESHED = 'songPage/SONG_REFRESHED';
export const songRefreshed = songId => ({
  type: SONG_REFRESHED,
  songId,
});

export const SONG_UPDATED = 'songPage/SONG_UPDATED';
export const songUpdated = (songId, {result, entities}) => ({
  type: SONG_UPDATED,
  songId,
  result,
  entities,
});

export const SONG_TIMESTAMP_UPDATED = 'songPage/SONG_TIMESTAMP_UPDATED';
export const songTimestampUpdated = songId => ({
  type: SONG_TIMESTAMP_UPDATED,
  songId,
});

export const SONG_REFRESH_FAILED = 'songPage/SONG_REFRESH_FAILED';
export const songRefreshFailed = songId => ({
  type: SONG_REFRESH_FAILED,
  songId,
});

export const LYRICS_REFRESHED = 'songPage/LYRICS_REFRESHED';
export const lyricsRefreshed = (songId, {forceRefresh = false} = {}) => ({
  type: LYRICS_REFRESHED,
  songId,
  forceRefresh,
});

export const LYRICS_REFRESH_FAILED = 'songPage/LYRICS_REFRESH_FAILED';
export const lyricsRefreshFailed = songId => ({
  type: LYRICS_REFRESH_FAILED,
  songId,
});

export const PENDING_LYRICS_EDIT_PROPOSALS_REQUESTED = 'songPage/PENDING_LYRICS_EDIT_PROPOSALS_REQUESTED';
export const pendingLyricsEditProposalsRequested = songId => ({
  type: PENDING_LYRICS_EDIT_PROPOSALS_REQUESTED,
  songId,
});

export const PENDING_LYRICS_EDIT_PROPOSALS_FAILED = 'songPage/PENDING_LYRICS_EDIT_PROPOSALS_FAILED';
export const pendingLyricsEditProposalsFailed = (songId, {message}) => ({
  type: PENDING_LYRICS_EDIT_PROPOSALS_FAILED,
  songId,
  message,
});

export const PENDING_LYRICS_EDIT_PROPOSALS_FETCHED = 'songPage/PENDING_LYRICS_EDIT_PROPOSALS_FETCHED';
export const pendingLyricsEditProposalsFetched = (songId, {result, entities}) => ({
  type: PENDING_LYRICS_EDIT_PROPOSALS_FETCHED,
  songId,
  result,
  entities,
});

export const RESOLVE_LYRICS_EDIT_PROPOSALS_TOGGLED = 'songPage/RESOLVE_LYRICS_EDIT_PROPOSALS_TOGGLED';
export const resolveLyricsEditProposalsToggled = ({isVisible, isModal}) => ({
  type: RESOLVE_LYRICS_EDIT_PROPOSALS_TOGGLED,
  isVisible,
  isModal,
});

export const ACCEPT_LYRICS_EDIT_PROPOSAL = 'songPage/ACCEPT_LYRICS_EDIT_PROPOSAL';
export const acceptLyricsEditProposal = (lyricsEditId, {lyricsEdit, patched, isLast, isModal}) => ({
  type: ACCEPT_LYRICS_EDIT_PROPOSAL,
  lyricsEditId,
  lyricsEdit,
  patched,
  isLast,
  isModal,
});

export const REJECT_LYRICS_EDIT_PROPOSAL = 'songPage/REJECT_LYRICS_EDIT_PROPOSAL';
export const rejectLyricsEditProposal = (lyricsEditId, {isLast, isModal}) => ({
  type: REJECT_LYRICS_EDIT_PROPOSAL,
  lyricsEditId,
  isLast,
  isModal,
});

export const RESOLVE_LYRICS_EDIT_PROPOSAL_FAILED = 'songPage/RESOLVE_LYRICS_EDIT_PROPOSAL_FAILED';
export const resolveLyricsEditProposalFailed = (lyricsEditId, message) => ({
  type: RESOLVE_LYRICS_EDIT_PROPOSAL_FAILED,
  lyricsEditId,
  message,
});

export const LYRICS_EDIT_PROPOSAL_STATE_SET = 'songPage/LYRICS_EDIT_PROPOSAL_STATE_SET';
export const lyricsEditProposalStateSet = (lyricsEditId, {state, hideLyricsEditProposals, lyricsData, songId, currentUserMetadata}) => ({
  type: LYRICS_EDIT_PROPOSAL_STATE_SET,
  lyricsEditId,
  state,
  hideLyricsEditProposals,
  lyricsData,
  songId,
  currentUserMetadata,
});

export const LYRICS_DATA_OPTIMISTIC_UPDATED = 'songPage/LYRICS_DATA_OPTIMISTIC_UPDATED';
export const lyricsDataOptimisticUpdated = lyricsData => ({
  type: LYRICS_DATA_OPTIMISTIC_UPDATED,
  payload: lyricsData,
});

export const LYRICS_DATA_REVERT_OPTIMISTIC_UPDATED = 'songPage/LYRICS_DATA_REVERT_OPTIMISTIC_UPDATED';
export const lyricsDataRevertOptimisticUpdated = lyricsData => ({
  type: LYRICS_DATA_REVERT_OPTIMISTIC_UPDATED,
  payload: lyricsData,
});

export const ADMIN_ACTION_PERFORMED = 'songPage/ADMIN_ACTION_PERFORMED';
export const adminActionPerformed = ({songId, action, data, showErrorInToast}, callbacks = {}) => ({
  type: ADMIN_ACTION_PERFORMED,
  payload: {
    songId,
    action,
    data,
    showErrorInToast,
  },
  meta: callbacks,
});

export const PREVIEW_FOR_LYRICS_EXPORT_REQUESTED = 'songPage/PREVIEW_FOR_LYRICS_EXPORT_REQUESTED';
export const previewForLyricsExportRequested = (songId, {onDone, onError}) => ({
  type: PREVIEW_FOR_LYRICS_EXPORT_REQUESTED,
  payload: {
    songId,
  },
  meta: {
    onDone,
    onError,
  },
});

export const PREVIEW_FOR_LYRICS_EXPORT_SUCCEEDED = 'songPage/PREVIEW_FOR_LYRICS_EXPORT_SUCCEEDED';
export const previewForLyricsExportSucceeded = (songId, {lyrics}) => ({
  type: PREVIEW_FOR_LYRICS_EXPORT_SUCCEEDED,
  payload: {
    songId,
    lyrics,
  },
});

export const SONG_DESTROYED = 'songPage/SONG_DESTROYED';
export const songDestroyed = (songId, {onSuccess}) => ({
  type: SONG_DESTROYED,
  payload: {
    songId,
  },
  meta: {
    onSuccess,
  },
});

export const SONG_RECOMMENDATIONS_REQUESTED = 'songPage/SONG_RECOMMENDATIONS_REQUESTED';
export const songRecommendationsRequested = songId => ({
  type: SONG_RECOMMENDATIONS_REQUESTED,
  songId,
});

export const SONG_RECOMMENDATIONS_FETCHED = 'songPage/SONG_RECOMMENDATIONS_FETCHED';
export const songRecommendationsFetched = (songId, {entities, result}) => ({
  type: SONG_RECOMMENDATIONS_FETCHED,
  songId,
  entities,
  result,
});

export const SONG_RECOMMENDATIONS_FETCH_FAILED = 'songPage/SONG_RECOMMENDATIONS_FETCH_FAILED';
export const songRecommendationsFetchFailed = songId => ({
  type: SONG_RECOMMENDATIONS_FETCH_FAILED,
  songId,
});

export const TRANSCRIPTION_PLAYER_VIDEO_TOGGLED = 'songPage/TRANSCRIPTION_PLAYER_VIDEO_TOGGLED';
export const transcriptionPlayerVideoToggled =  () => ({
  type: TRANSCRIPTION_PLAYER_VIDEO_TOGGLED,
});

export const TRENDING_SONGS_REQUESTED = 'songPage/TRENDING_SONGS_REQUESTED';
export const trendingSongsRequested = () => ({
  type: TRENDING_SONGS_REQUESTED,
});

export const TRENDING_SONGS_FETCHED = 'songPage/TRENDING_SONGS_FETCHED';
export const trendingSongsFetched = ({entities, result}) => ({
  type: TRENDING_SONGS_FETCHED,
  entities,
  result,
});

export const TRENDING_SONGS_FETCH_FAILED = 'songPage/TRENDING_SONGS_FETCH_FAILED';
export const trendingSongsFetchFailed = () => ({
  type: TRENDING_SONGS_FETCH_FAILED,
});

export const TOGGLE_CONTRIBUTOR_SIDEBAR = 'songPage/TOGGLE_CONTRIBUTOR_SIDEBAR';
export const toggleContributorSidebar = () => ({
  type: TOGGLE_CONTRIBUTOR_SIDEBAR,
});

export const TOGGLE_ATTRIBUTIONS = 'songPage/TOGGLE_ATTRIBUTIONS';
export const toggleAttributions = (entity, id, visible) => ({
  type: TOGGLE_ATTRIBUTIONS,
  entity,
  id,
  visible,
});

export const TOGGLE_CONTRIBUTIONS_MODAL = 'songPage/TOGGLE_CONTRIBUTIONS_MODAL';
export const toggleContributionsModal = visible => ({
  type: TOGGLE_CONTRIBUTIONS_MODAL,
  visible,
});

export const UNREVIEWED_TOP_ANNOTATIONS_REQUESTED = 'songPage/UNREVIEWED_TOP_ANNOTATIONS_REQUESTED';
export const unreviewedTopAnnotationsRequested = songId => ({
  type: UNREVIEWED_TOP_ANNOTATIONS_REQUESTED,
  payload: {
    songId,
  },
});

export const UNREVIEWED_TOP_ANNOTATIONS_FETCH_FAILED = 'songPage/UNREVIEWED_TOP_ANNOTATIONS_FETCH_FAILED';
export const unreviewedTopAnnotationsFetchFailed = songId => ({
  type: UNREVIEWED_TOP_ANNOTATIONS_FETCH_FAILED,
  songId,
});

export const UNREVIEWED_TOP_ANNOTATIONS_FETCHED = 'songPage/UNREVIEWED_TOP_ANNOTATIONS_FETCHED';
export const unreviewedTopAnnotationsFetched = (songId, {result, entities}) => ({
  type: UNREVIEWED_TOP_ANNOTATIONS_FETCHED,
  songId,
  result,
  entities,
});

export const SONG_FOLLOW = 'songPage/SONG_FOLLOW';
export const songFollow = (songId, callbacks) => ({
  type: SONG_FOLLOW,
  payload: {songId},
  meta: callbacks,
});

export const SONG_UNFOLLOW = 'songPage/SONG_UNFOLLOW';
export const songUnfollow = (songId, callbacks) => ({
  type: SONG_UNFOLLOW,
  payload: {songId},
  meta: callbacks,
});
