import {useMemo} from 'react';

export default (releaseDateComponents) => {
  const value = useMemo(() => {
    if (!releaseDateComponents) return null;

    const today = new Date();
    const {year, month, day} = releaseDateComponents;

    if (year < today.getFullYear()) {
      return day ? 'past' : 'past_no_day_or_month';
    }

    if (year === today.getFullYear() && month) {
      if (month < today.getMonth() + 1) {
        return day ? 'past' : 'past_no_day_or_month';
      }
      if (month === today.getMonth() + 1) {
        return day && today.getDate() > day ? 'past' : 'future';
      }
    }

    return 'future';
  }, [releaseDateComponents]);

  return value ? `edit_metadata.tags_albums.release_date_relative.${value}` : null;
};
