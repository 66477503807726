import {get, post, put, destroy} from './client';
import {annotationSchema, referentSchema, versionSchema, annotationWithReferentSchema, annotationEditSchema, normalize} from '@/normalize';
import {snakifyKeys} from '@/util';

export const getReferent = async (baseAnnotationId) => {
  const response = await get(`/referents/${baseAnnotationId}`);
  return normalize(response.referent, referentSchema);
};

export const createReferent = async (songId, data) => {
  const {community, updated_by_human_at, ...referentData} = snakifyKeys(data);
  const {referent} = await post(`/songs/${songId}/referents`, {
    community,
    referent: referentData,
    song: {
      id: songId,
      updated_by_human_at,
    },
  });
  return normalize(referent, referentSchema);
};

export const createAnnotation = async (id, data) => {
  const response = await post(`/referents/${id}/annotations`, {annotation: data});
  return normalize(response, annotationWithReferentSchema);
};

export const updateAnnotation = async (id, data) => {
  const response = await put(`/annotations/${id}`, {
    annotation: snakifyKeys(data),
  });
  return normalize(response, annotationWithReferentSchema);
};

export const removeAnnotation = async id => await destroy(`/annotations/${id}`);

export const getAnnotationVersions = async (id) => {
  const response = await get(`/annotations/${id}/versions`);
  return normalize(response, {versions: [versionSchema]});
};

export const proposeAnnotationEdit = async (annotationId, data) => {
  const {annotation_edit} = await post(`/annotations/${annotationId}/edits`, {
    annotation: snakifyKeys(data),
  });
  return normalize(annotation_edit, annotationEditSchema);
};

export const acceptAnnotation = async (id) => {
  const response = await post(`/annotations/${id}/accept`);
  return normalize(response, annotationWithReferentSchema);
};

export const rejectAnnotation = async (id, {body, reason}) => {
  const response = await post(`/annotations/${id}/reject`, {
    annotation_id: id,
    comment: {body, reason},
  });
  return normalize(response, annotationWithReferentSchema);
};

export const getAnnotationEditProposals = async (id) => {
  const {annotation_edits} = await get(`/annotations/${id}/edits`);
  return normalize(annotation_edits, [annotationEditSchema]);
};

export const actOnProposedEdit = async (action, editId) => {
  const {annotation_edit} = await post(`/annotation_edits/${editId}/${action}`);
  return normalize(annotation_edit, annotationEditSchema);
};

export const changeAnnotationVersion = async (annotationId, versionId) => {
  const response = await put(`/annotations/${annotationId}/version`, {
    version_id: versionId,
  });
  return normalize(response, {annotation: annotationSchema, referent: referentSchema});
};

export const toggleAnnotationCosign = async (annotationId, cosigned) => {
  const actionPath = cosigned ? 'uncosign' : 'cosign';
  const response = await put(`/annotations/${annotationId}/${actionPath}`);
  return normalize(response, {annotation: annotationSchema, referent: referentSchema});
};

export const toggleAnnotationPinnedToProfile = async (annotationId, currentlyPinned) => {
  const actionPath = currentlyPinned ? 'unpin_from_profile' : 'pin_to_profile';
  const response = await put(`/annotations/${annotationId}/${actionPath}`);
  return normalize(response, annotationWithReferentSchema);
};
