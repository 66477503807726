import {schema, normalize as originalNormalize} from 'normalizr';
import {camelizeKeys} from '@/util';

export const userSchema = new schema.Entity('users');

export const userAttributionSchema = {user: userSchema};

export const articleSchema = new schema.Entity('articles', {author: userSchema});

export const videoSchema = new schema.Entity('videos', {author: userSchema});

export const videoListSchema = new schema.Entity('videoLists', {latestEpisode: videoSchema});

export const commentSchema = new schema.Entity('comments', {author: userSchema});

export const commentsSchema = new schema.Object({comments: [commentSchema]});

export const contributorsSchema = new schema.Object({contributors: {
  iqEarners: [userAttributionSchema],
  transcribers: [userAttributionSchema],
}});

export const votersSchema = new schema.Object({voters: {
  up: [userSchema],
  down: [userSchema],
}});

export const versionSchema = new schema.Entity('versions', {
  user: userSchema,
});

export const annotationEditSchema = new schema.Entity('annotationEdits', {
  createdBy: userSchema,
});

export const annotationSchema = new schema.Entity('annotations', {
  authors: [{
    user: userSchema,
  }],
  cosignedBy: [userSchema],
  verifiedBy: userSchema,
  createdBy: userSchema,
  acceptedBy: userSchema,
  topComment: commentSchema,
});

export const referentSchema = new schema.Entity('referents', {
  annotations: [annotationSchema],
});

export const bulkReferentsSchema = new schema.Values(referentSchema);

export const referentsSchema = new schema.Object({
  referents: [referentSchema],
});

export const annotationWithReferentSchema = new schema.Object({
  annotation: annotationSchema,
  referent: referentSchema,
});

export const artistSchema = new schema.Entity('artists');

export const verifiedContributorSchema = new schema.Object({
  user: userSchema,
  artist: artistSchema,
});

export const stubhubArtistSchema = new schema.Entity('stubhubArtists', {
  artist: artistSchema,
});

export const tagSchema = new schema.Entity('tags');

export const artistAutocompleteSchema = new schema.Array(artistSchema);

export const tagAutocompleteSchema = new schema.Array(tagSchema);

export const albumAppearanceSchema = new schema.Entity('albumAppearances', {}, {
  idAttribute: value => value.song.id,
});

export const customPerformanceRoleSchema = new schema.Entity('customPerformanceRoles');

export const customPerformanceRoleAutocompleteSchema = new schema.Array(customPerformanceRoleSchema);

export const albumSchema = new schema.Entity('albums', {
  artist: artistSchema,
  primaryArtists: [artistSchema],
  tracklist: [albumAppearanceSchema],
});

export const songSchema = new schema.Entity('songs', {
  album: albumSchema,
  albums: [albumSchema],
  primaryArtist: artistSchema,
  primaryArtists: [artistSchema],
  verifiedContributors: [verifiedContributorSchema],
  descriptionAnnotation: referentSchema,
  lyricsMarkedCompleteBy: userSchema,
  lyricsMarkedStaffApprovedBy: userSchema,
  stubhubArtist: stubhubArtistSchema,
});

songSchema.define({nextSong: songSchema});

export const songRecommendationSchema = new schema.Object({
  recommendations: [{recommendedSong: songSchema}],
});

export const lyricsEditProposalSchema = new schema.Entity('lyricsEditProposals', {
  user: userSchema,
});

export const pendingLyricsEditProposalsSchema = [lyricsEditProposalSchema];

albumAppearanceSchema.define({song: songSchema});

export const lyricsDataSchema = new schema.Object({
  referents: [referentSchema],
});

export const editorialPlacementSchema = {
  content: new schema.Union({
    articles: articleSchema,
    videos: videoSchema,
  }, input => `${input.type}s`),
};

export const chartItemSchema = {
  item: new schema.Union({
    songs: songSchema,
    artists: artistSchema,
    albums: albumSchema,
    referents: referentSchema,
  }, ({type}) => `${type}s`),
};

export const answerSchema = new schema.Entity('answers', {
  authors: [{user: userSchema}],
});

export const questionSchema = new schema.Entity('questions', {
  author: userSchema,
  answer: answerSchema,
  questionable: new schema.Union({
    songs: songSchema,
    albums: albumSchema,
  }, (_item, parent) => `${parent.questionableType}s`),
});

answerSchema.define({
  question: questionSchema,
});

export const forumPostSchema = new schema.Entity('forumPosts', {
  author: userSchema,
});

export const groupSchema = new schema.Entity('groups');

export const discussionSchema = new schema.Entity('discussions', {
  firstPost: forumPostSchema,
  latestPosts: [forumPostSchema],
  author: userSchema,
  latestPostAuthor: userSchema,
  group: groupSchema,
});

export const questionAnswerSchema = new schema.Entity('questionAnswers', {
  questionable: new schema.Union({
    songs: songSchema,
    albums: albumSchema,
    artists: artistSchema,
  }, (_item, parent) => `${parent.questionableType}s`),
});

export const relatedQuestionsSchema = new schema.Entity('relatedQuestions', {
  questions: [questionAnswerSchema],
  questionable: new schema.Union({
    songs: songSchema,
    albums: albumSchema,
    artists: artistSchema,
  }, (_item, parent) => `${parent.questionableType}s`),
});

export const defaultQuestionsSchema = new schema.Entity('defaultQuestions');

export const voteableSchema = new schema.Object({
  annotation: annotationSchema,
  question: questionSchema,
  answer: answerSchema,
  comment: commentSchema,
  video: videoSchema,
  article: articleSchema,
});

export const pyongableSchema = new schema.Object({
  annotation: annotationSchema,
  referent: referentSchema,
  song: songSchema,
});

export const discussionPageSchema = {
  discussion: discussionSchema,
  session: {
    currentUser: userSchema,
  },
};

export const homePageSchema = {
  home: {
    topSection: {editorialPlacements: [editorialPlacementSchema]},
    chartSection: {chartItems: [chartItemSchema]},
    videoSection: {videoLists: [videoListSchema], featuredVideoList: videoListSchema},
    featuresSection: {editorialPlacements: [editorialPlacementSchema]},
    latestSection: {editorialPlacements: [editorialPlacementSchema]},
    communitySection: {leaderboard: [userAttributionSchema]},
  },
  session: {
    currentUser: userSchema,
  },
};

export const songPageSchema = {
  songPage: {
    song: songSchema,
    pinnedQuestions: [questionSchema],
    lyricsData: lyricsDataSchema,
    metadataQuestions: [questionAnswerSchema],
    unreviewedTopAnnotations: referentsSchema,
    defaultQuestions: [defaultQuestionsSchema],
  },
  session: {
    currentUser: userSchema,
  },
};

export const songRelationshipsPageSchema = {
  songRelationshipsPage: {
    song: songSchema,
  },
  session: {
    currentUser: userSchema,
  },
};

export const discographySchema = new schema.Union({
  albums: albumSchema,
  songs: songSchema,
}, item => `${item.type}s`);

export const artistDiscographySchema = {
  artistDiscography: {
    artist: artistSchema,
    items: [discographySchema],
    popularItems: [discographySchema],
  },
  session: {
    currentUser: userSchema,
  },
};

export const questionAnswerPageSchema = {
  session: {
    currentUser: userSchema,
  },
  questionAnswerPage: {
    questionAnswer: questionAnswerSchema,
    relatedQuestions: [relatedQuestionsSchema],
  },
};

export const normalize = (obj, objSchema) => originalNormalize(camelizeKeys(obj), objSchema);
