import round from 'lodash/round';

export const hexToRgb = (color) => {
  let colorVal = color;
  if (color.length === 4) {
    colorVal = `#${color[1]}${color[1]}${color[2]}${color[2]}${color[3]}${color[3]}`;
  }

  const m = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(colorVal);

  return m ? [m[1], m[2], m[3]].map(s => parseInt(s, 16)) : null;
};

export const calculateContrast = (color1, color2) => {
  if (!color1 || !color2) {
    return 1;
  }

  const color1RGB = hexToRgb(color1);
  const color2RGB = hexToRgb(color2);

  const color1Luminance = luminance(color1RGB);
  const color2Luminance = luminance(color2RGB);

  return color1Luminance > color2Luminance
    ? round((color1Luminance + 0.05) / (color2Luminance + 0.05), 2)
    : round((color2Luminance + 0.05) / (color1Luminance + 0.05), 2);
};

export const luminance = (rgbArray) => {
  const a = rgbArray.map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return (a[0] * 0.2126) + (a[1] * 0.7152) + (a[2] * 0.0722);
};
