import {createLogicMiddleware, createLogic} from 'redux-logic';
import get from 'lodash/get';
import {
  NEW_INBOX_EVENT,
  INBOX_MARKED_READ,
  unreadCountsLoaded,
  USER_LOGGED_OUT,
  userLoggedOutFailed,
  IMPERSONATED_USER,
  impersonatedUserFailed,
  REMOVE_ASSET_REQUESTED,
  removeAssetSucceeded,
  removeAssetFailed,
  MIXPANEL_EVENT_TRIGGERED,
  AD_REQUESTED,
  AD_REFRESH_REQUESTED,
  adEmpty,
  adFilled,
  adImpressionViewable,
} from '@/actions';
import {getUnreadCounts, logout, impersonateSession, removeAsset} from '@/api';
import bugsnag from '@/bugsnag';
import {track} from '@/mixpanel';
import {render, refresh} from '@/util/ads';

const unreadCountsLogic = createLogic({
  type: [NEW_INBOX_EVENT, INBOX_MARKED_READ],
  latest: true,
  process: ({action: {userId}}, dispatch, done) => {
    getUnreadCounts().
      then(response => dispatch(unreadCountsLoaded({unreadCounts: response, userId}))).
      catch(bugsnag.notify).
      then(done);
  },
});

const logoutLogic = createLogic({
  type: USER_LOGGED_OUT,
  latest: true,
  process: (_deps, dispatch, done) => {
    logout().
      then(() => window.location.reload()).
      catch((e) => {
        bugsnag.notify(e);
        dispatch(userLoggedOutFailed());
      }).
      then(done);
  },
});

const impersonationLogic = createLogic({
  type: IMPERSONATED_USER,
  latest: true,
  process: ({action: {login}}, dispatch, done) => {
    impersonateSession(login).
      then(() => window.location.reload()).
      catch((e) => {
        bugsnag.notify(e);
        dispatch(impersonatedUserFailed());
      }).
      then(done);
  },
});

const removeAssetLogic = createLogic({
  type: REMOVE_ASSET_REQUESTED,
  process: ({action: {url}}, dispatch, done) => {
    removeAsset(url).
      then(() => dispatch(removeAssetSucceeded())).
      catch((e) => {
        bugsnag.notify(e);
        dispatch(removeAssetFailed());
      }).
      then(done);
  },
});

const trackingLogic = createLogic({
  type: MIXPANEL_EVENT_TRIGGERED,
  process: ({getState, action: {eventName, eventOptions, requestOptions, callback}}) => {
    track(eventName, eventOptions, getState(), requestOptions, callback);
  },
});

const requestAdLogic = createLogic({
  type: AD_REQUESTED,
  async process({action: {name, instance}}, dispatch, done) {
    try {
      const event = await render(name, instance);

      if (event.isEmpty) {
        dispatch(adEmpty(name, instance));
      } else {
        dispatch(adFilled(name, instance, {width: event.size[0], height: event.size[1], creativeId: event.creativeId}));

        await event.impressionViewable;
        dispatch(adImpressionViewable(name, instance));
      }
    } catch (e) {
      bugsnag.notify(e);
      dispatch(adEmpty(name, instance));
    } finally {
      done();
    }
  },
});

const refreshAdLogic = createLogic({
  type: AD_REFRESH_REQUESTED,
  async process({getState, action: {name, instance}}, dispatch, done) {
    try {
      const maxHeight = get(getState(), ['ads', name, instance, 'containerHeight']);
      const event = await refresh(name, instance, maxHeight);

      dispatch(adFilled(name, instance, {width: event.size[0], height: event.size[1], creativeId: event.creativeId}));

      await event.impressionViewable;
      dispatch(adImpressionViewable(name, instance));
    } catch (e) {
      bugsnag.notify(e);
      dispatch(adEmpty(name, instance));
    } finally {
      done();
    }
  },
});

export default (logics = []) => () => {
  const middleware = createLogicMiddleware([
    unreadCountsLogic,
    logoutLogic,
    impersonationLogic,
    removeAssetLogic,
    trackingLogic,
    requestAdLogic,
    refreshAdLogic,
    ...logics,
  ]);
  middleware.monitor$.subscribe(({op, err}) => {
    if (op === 'nextError') {
      bugsnag.notify(err);
    }
  });
  return middleware;
};
