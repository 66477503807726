export const REFERENTS_BATCH_REQUESTED = 'songPage/REFERENTS_BATCH_REQUESTED';
export const referentsBatchRequested = ({ids, onDone}) => ({
  type: REFERENTS_BATCH_REQUESTED,
  payload: {
    ids,
    onDone,
  },
});

export const REFERENTS_BATCH_FETCHED = 'songPage/REFERENTS_BATCH_FETCHED';
export const referentsBatchFetched = ({entities}) => ({
  type: REFERENTS_BATCH_FETCHED,
  entities,
});

export const REFERENTS_BATCH_FETCH_FAILED = 'songPage/REFERENTS_BATCH_FETCH_FAILED';
export const referentsBatchFetchFailed = ({message}) => ({
  type: REFERENTS_BATCH_FETCH_FAILED,
  message,
});
