const layerByName = {
  sentinel: -2,
  skin: -1,
  squareSelect: 1,
  annotationToggle: 1,
  mobileAdhesion: 2,
  annotationPortal: 2,
  contributorStickyNav: 3,
  transcriptionPlayerDismissButton: 3,
  headerDropdown: 3,
  dropdown: 4,
  songHeader: 4,
  mobileSubnavigation: 4,
  mobileSearchInput: 4,
  bottomSticky: 4,
  tooltip: 5,
  stickyLeaderboardOrMarquee: 6,
  stickyNav: 6,
  gdprBanner: 7,
  modalLower: 8,
  modalUpper: 9,
  mobileOverlayForm: 10,
  toast: 11,
  portalTooltip: 11,
  draggingValue: 1000,
};

export default (name) => {
  const layer = layerByName[name];
  if (!layer) throw new Error(`No z-index entry for ${name}`);
  return `z-index: ${layer};`;
};
