import {useEffect, useCallback} from 'react';
import {useIsMobile} from '@/hooks';

export default ({element, onClickOutside}) => {
  const isMobile = useIsMobile();

  const onClick = useCallback((event) => {
    if (element.current === null) return;
    if (element.current.contains(event.target)) return;
    if (event.composedPath().some(el => Boolean(el.dataset?.ignoreOnClickOutside))) return;
    onClickOutside(event);
  }, [onClickOutside, element]);

  useEffect(() => {
    const touchStartEvent = isMobile ? 'click' : 'mousedown';
    document.addEventListener(touchStartEvent, onClick, {capture: true});
    document.addEventListener('touchend', onClick);
    return () => {
      document.removeEventListener(touchStartEvent, onClick, {capture: true});
      document.removeEventListener('touchend', onClick);
    };
  }, [onClick, isMobile]);
};
