import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {breakWord, overflowWithLineClamp, blockquote} from '@/style';
import {isServer} from '@/util';

const isIntraPageLink = url => url.startsWith(`${location.origin}/`);

const RichText = ({className, as, html}) => {
  const handleLinks = useCallback((e) => {
    const anchor = e.target.closest('a');

    if (anchor && !anchor.target && !isIntraPageLink(anchor.href)) {
      anchor.target = '_blank';
    }
  }, []);

  const addHtml = useCallback((ref) => {
    if (ref) {
      const range = document.createRange();
      range.selectNode(ref);
      ref.innerHTML = '';
      ref.appendChild(range.createContextualFragment(html));
    }
  }, [html]);

  return isServer ?
    <RichText.Container className={className} as={as} onClick={handleLinks} dangerouslySetInnerHTML={{__html: html}} /> :
    <RichText.Container className={className} as={as} onClick={handleLinks} ref={addHtml} />;
};

RichText.propTypes = {
  className: PropTypes.string,
  as: PropTypes.string,
  html: PropTypes.string.isRequired,
};

export default React.memo(RichText);

const MAX_LINES_FOR_EMBEDLY_PREVIEW = 3;

RichText.Container = styled.div`
  ${breakWord}
  font: 100 ${p => p.theme.fontSize.reading}/${p => p.theme.lineHeight.reading} ${p => p.theme.font.reading};

  h1 { font-size: ${p => p.theme.fontSize.smallHeadline}; }

  h2 { font-size: ${p => p.theme.fontSize.xSmallHeadline}; }

  h3 { font-size: ${p => p.theme.fontSize.largeReading}; }

  h4, h5, h6 { font-size: ${p => p.theme.fontSize.reading}; }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${p => p.theme.font.accent};
    font-weight: 600;
    margin: ${p => p.theme.fontSize.reading} 0 0;
  }

  a {
    color: ${p => p.theme.color.background.on};
    text-decoration: underline;
  }

  p { margin: ${p => p.theme.space.full} 0; }

  p:empty { display: none; }

  small { font-size: ${p => p.theme.fontSize.smallReading}; }

  img {
    display: block;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    max-height: 325px;
    width: auto;
  }

  blockquote {
    ${blockquote}
  }

  pre {
    font-family: inherit;
    margin: ${p => p.theme.space.full} 0;
  }

  code {
    font-family: ${p => p.theme.font.monospace};
    font-size: ${p => p.theme.fontSize.smallReading};
  }

  table {
    margin: ${p => p.theme.space.full} 0;
    width: 100%;
  }

  th, td { padding: ${p => p.theme.space.half} ${p => p.theme.space.half} 0; }

  th {
    border-bottom: 1px solid ${p => p.theme.color.background.variant};
    font-weight: bold;
    text-align: left;
  }

  ul, ol {
    list-style-type: none;
    margin: ${p => p.theme.space.full} 0;
    padding: 0;

    ul, ol {
      margin: 0;
      padding: 0 0 0 ${p => p.theme.space.full};
    }

    li {
      padding-left: ${p => p.theme.listIndentationWidth};
      position: relative;
    }
  }

  ul li:before {
    content: '\\2022';
    left: calc(${p => p.theme.listIndentationWidth} / 2.5);
    position: absolute;
  }

  ol {
    counter-reset: ordered_list_counter;

    li:before {
      font-feature-settings: 'tnum';
      content: counter(ordered_list_counter) '. ';
      counter-increment: ordered_list_counter;
      left: -${p => p.theme.space.half};
      position: absolute;
      text-align: right;
      width: ${p => p.theme.listIndentationWidth};
    }
  }

  dd { margin: 0 0 0 ${p => p.theme.space.full}; }

  hr {
    border: 1px solid currentcolor;
    border-width: 1px 0 0;
  }

  iframe { max-width: 100% }

  ins, ins > * {
    text-decoration: none;
    background: ${p => p.theme.color.diff.insert};

    img {
      border: 2px solid ${p => p.theme.color.diff.insert};
    }
  }

  del, del > * {
    background: ${p => p.theme.color.diff.delete};

    img {
      border: 2px solid ${p => p.theme.color.diff.delete};
    }
  }

  .embedly_preview {
    font-size: 1rem;
    margin-top: ${p => p.theme.space.full};
    margin-bottom: ${p => p.theme.space.full};

    a { font-weight: bold; }

    iframe {
      display: block;
      margin: auto;
      width: 100%;
    }
  }

  .embedly_preview--video, .embedly_preview--vertical-video {
    position: relative;
    width: 100%;
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .embedly_preview--vertical-video {
    padding-bottom: ${100 * (16 / 9)}%;
  }

  .embedly_preview--video {
    padding-bottom: ${100 * (9 / 16)}%;
  }

  .embedly_preview-thumb {
    position: relative;
    height: 0;
    width: 100%;
    padding-bottom: 50%;
    overflow: hidden;
    border-bottom: 1px solid ${p => p.theme.color.background.on};

    img {
      position: absolute;
      top: 0;
      left: 0;
      min-width: 100%;
      min-height: 100%;
    }
  }

  .embedly_preview .gray_container {
    display: flex;
    flex-direction: column;
    border: 1px solid ${p => p.theme.color.background.on};
    font-weight: ${p => p.theme.fontWeight.normal};
    color: inherit;
    ${breakWord};

    &:hover {
      background-color: ${p => p.theme.color.background.on};
      color: ${p => p.theme.color.background.main};
    }
  }

  .embedly_preview > a {
    text-decoration: none;
  }

  .embedly_preview .gray_container .embedly_preview-text {
    display: flex;
    flex-direction: column;
    padding: ${p => p.theme.space.small};
    font-weight: ${p => p.theme.fontWeight.light};

    .embedly_preview-provider {
      order: 1;
      font-size: ${p => p.theme.fontSize.smallReading};
      margin-bottom: ${p => p.theme.space.quarter};
    }

    .embedly_preview-dash { display: none; }

    .embedly_preview-title {
      order: 2;
      margin-bottom: ${p => p.theme.space.quarter};
      line-height: ${p => p.theme.lineHeight.medium};
    }

    .embedly_preview-description {
      order: 3;
      font-size: ${p => p.theme.fontSize.smallReading};
      line-height: ${p => p.theme.lineHeight.reading};
      ${overflowWithLineClamp(MAX_LINES_FOR_EMBEDLY_PREVIEW, 'reading')};
    }
  }
`;
