import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {useMixpanelEvent} from '@/hooks';
import {rejectAnnotation} from '@/songPage/actions';
import {applyValidationErrors} from '@/util/forms';

export default (id, isDescription, dispatcher = 'song') => {
  const dispatch = useDispatch();
  const trackingPrefix = isDescription ? 'description_annotation' : 'annotation';
  const trackReject = useMixpanelEvent(`${dispatcher}:unreviewed_${trackingPrefix}_rejected`, {annotation_id: id});
  const trackOpenRejectAnnotation = useMixpanelEvent(`${dispatcher}:complete_song:unreviewed_${trackingPrefix}_reject_toggled`, {annotation_id: id});
  const trackCancelRejectAnnotation = useMixpanelEvent(`${dispatcher}:complete_song:unreviewed_${trackingPrefix}_reject_cancel`, {annotation_id: id});

  const trackToggleRejectForm = useCallback(showRejectForm => (
    showRejectForm ? trackOpenRejectAnnotation() : trackCancelRejectAnnotation()
  ), [trackCancelRejectAnnotation, trackOpenRejectAnnotation]);

  const handleReject = useCallback(({body, reason, setError}) =>
    new Promise((resolve, reject) => {
      dispatch(rejectAnnotation(id, {
        body,
        reason,
        onSuccess: () => {
          resolve();
          trackReject({reason});
        },
        onError: (e) => {
          applyValidationErrors(e.validation_errors, setError);
          reject(e);
        },
      }));
    })
  , [id, dispatch, trackReject]);

  return {
    rejectAnnotation: handleReject,
    trackToggleRejectForm,
  };
};
