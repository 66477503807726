import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

const STEPS = [
  {amount: 60, unit: 'seconds'},
  {amount: 60, unit: 'minutes'},
  {amount: 24, unit: 'hours'},
  {amount: 30.4167, unit: 'days'},
  {amount: 12, unit: 'months'},
];

const getDurationAndUnit = (fromDate, toDate) => {
  let duration = (toDate - fromDate) / 1000;

  for (const {amount, unit} of STEPS) {
    if (duration < amount) {
      return {duration: Math.round(duration), unit};
    }
    duration = duration / amount;
  }

  return {duration: Math.round(duration), unit: 'years'};
};

const RelativeTimestamp = ({time, refreshInterval, className}) => {
  const {t} = useTranslation();
  const timeAsDate = new Date(time * 1000);
  const [dateNow, setDateNow] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setDateNow(Date.now()), refreshInterval);
    return () => {
      clearInterval(interval);
    };
  }, [refreshInterval]);

  const {duration, unit} = getDurationAndUnit(timeAsDate, dateNow);

  return (
    <time title={timeAsDate.toLocaleString()} dateTime={timeAsDate.toISOString()} className={className}>
      {t(`relative_timestamp.${unit}`, {count: duration})}
    </time>
  );
};

RelativeTimestamp.propTypes = {
  refreshInterval: PropTypes.number,
  time: PropTypes.number.isRequired,
  className: PropTypes.string,
};

RelativeTimestamp.defaultProps = {
  refreshInterval: 10000,
};

export default RelativeTimestamp;
