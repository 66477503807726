import {useSelector} from 'react-redux';
import {can} from '@/util';

import {useLoggedIn, useEntity, useIsMobile} from '@/hooks';
import {useIsShellPage} from '@/songPage/hooks';

export default (songId) => {
  const loggedIn = useLoggedIn();
  const isMobile = useIsMobile();
  const showAds = useSelector(state => state.session.showAds);
  const song = useEntity('songs', songId);
  const {isMusic} = song;
  const isShellPage = useIsShellPage();
  const forceShowContributorSidebar = useSelector(state => state.songPage.forceShowContributorSidebar);
  const canCloseContributorSidebar = loggedIn && showAds;

  const canEditLyrics = can('edit_lyrics', song);

  if (!loggedIn || isMobile || !isMusic) {
    return false;
  }

  if (canCloseContributorSidebar) {
    return forceShowContributorSidebar;
  }

  return canEditLyrics || !isShellPage;
};
