import {AppConfig} from '@/config';
import {useLoggedIn} from '@/hooks';

export default () => {
  const loggedIn = useLoggedIn();

  const links = [
    {label: 'featured', action: '/#featured-stories'},
    {label: 'charts', action: '/#top-songs'},
    {label: 'videos', action: '/#videos'},
  ];

  if (AppConfig.artistPromoPortalLaunched) {
    links.push({
      label: `artist_promo_portal.${loggedIn ? 'logged_in' : 'default'}`,
      action: 'https://promote.genius.com',
    });
  } else {
    links.push({label: 'shop', action: 'https://shop.genius.com'});
  }

  if (loggedIn) {
    links.push(
      {label: 'forums', action: '/forums'},
      {label: 'add_a_song', action: '/new'},
    );
  }

  if (!!AppConfig.micrositeLabel) {
    links.unshift(
      {
        label: AppConfig.micrositeLabel,
        useLabelTranslation: false,
        action: AppConfig.micrositeUrl,
      },
    );
  }

  return {links, loggedIn};
};
