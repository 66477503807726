import {useEntity} from '@/hooks';
import {can} from '@/util';
import {useIsShellPage} from '@/songPage/hooks';

const useSidebarLyricsTracker = (songId) => {
  const song = useEntity('songs', songId);
  const isShellPage = useIsShellPage();
  const canMarkLyricsComplete = can('mark_lyrics_evaluation_as_complete', song);

  const {
    lyricsMarkedStaffApprovedBy,
    lyricsMarkedCompleteBy,
    lyricsVerified,
  } = song;

  const lyricsCompleted = lyricsMarkedCompleteBy || lyricsMarkedStaffApprovedBy || (lyricsVerified && !canMarkLyricsComplete);

  return {
    lyricsExist: !isShellPage,
    lyricsCompleted,
  };
};

export default useSidebarLyricsTracker;
