import {useEntity} from '@/hooks';
import get from 'lodash/get';
import {useSelector} from 'react-redux';

export default (songId) => {
  const song = useEntity('songs', songId);
  const album = useEntity('albums', song.album);
  const hasNextSong = useSelector(state => get(state, ['songPage', 'trackingData', 'has_next_song_experiment'], false));
  if (!hasNextSong) {
    return null;
  }

  if (Boolean(song.album)) {
    const inTracklistAt = album.tracklist.indexOf(songId);
    if (inTracklistAt < (album.tracklist.length - 1)) {
      return {
        songId: album.tracklist[inTracklistAt + 1],
        albumId: album.id,
      };
    }
  }

  if (Boolean(song.songRecommendations)) {
    return {
      songId: song.songRecommendations.recommendations[0].recommendedSong,
    };
  }

  return null;
};
